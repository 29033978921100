<template>
    <Navbar></Navbar>
    <div class="mint-page-container">
        <div class="container">
            <h2 style="color: white; margin: 0px; margin: 2vh; text-shadow: #000000 1px 0 10px;">Mint Mansions</h2>
            <div class="mint-mansions-container">
                <div class="mint-mansions-image">
                    <img src="/img/oni-mansion-banner-frame-gif.e39da0b3.gif" id="mansion-img-gif" alt="Oni Mansion Game GIF">
                </div>
                <div class="mint-mansions-content">
                    <div class="mint-mansions blurred-card" style="font-size: 20px; margin-bottom: 4rem; margin-top: 4rem; margin-right: 2rem; text-shadow: #000000 1px 0 10px;">
                        <p>The only way to mint a Mansion NFT is if you played Oni Mansion, the first crypto game enabling players to customize and create their own NFT.</p>
                        <br>
                        <p>Oni Mansions will also feature generative music that can be played right on your OpenSea listing! Each mansion receives a unique tune, generated and assembled from 142 unique musical segments. Oni Mansion NFTs also have lightly animated backgrounds to bring them even more to life.</p>
                        <br>
                        <p>To download your high-res Mansion banner for Twitter, simply log-in to your Mansion at the game site: <a href="https://onisquad.gg/#/oni-mansion-game/" target="_blank">https://onisquad.gg/#/oni-mansion-game/</a></p>
                        <br>
                        <template v-if="ready">
                            <div style="display: flex; flex-direction: row; justify-content: start; align-items: center;">
                                <h3 style="color: white; font-size: 28px; margin: 0px; margin-right: 1rem; text-align: left;">You Can Mint {{maxMintNumber}} NFT{{maxMintNumber === 1 ? '' : 's'}}</h3>
                                <div class="tooltip">
                                    <div class="tooltip-icon">?</div>
                                    <div class="tooltiptext">
                                        <ul>
                                            <li>- You can mint a total of {{eligibleOwnedNfts.length}} NFT{{eligibleOwnedNfts.length === 1 ? '' : 's'}}.</li>
                                            <li>- You minted {{ownedMansionNfts.length}} Oni Mansion NFT{{ownedMansionNfts.length === 1 ? '' : 's'}}.</li>
                                            <li>- You can mint {{maxMintNumber}} Oni Mansion NFT{{maxMintNumber === 1 ? '' : 's'}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p style="color: white; margin: 0px; text-align: left; margin-bottom: 1rem;">Select the amount of Mansions you want to mint</p>
                            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                                <input id="mintAmount" v-model="mintAmount" style="font-size: 28px;" type="number" :min="0">
                                <div class="modal-content connect-button" @click="mint()">
                                    <div class="contents inline-menu mt-15">
                                        <div class="p-discord">
                                            <div class="p-btn mb-10">
                                                <a class="mint-link" style="line-height: 32px; cursor: pointer; text-shadow: none;">Mint</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h3 v-if="error" style="color: red; font-size: 20px; margin: 0px; margin-right: 1rem; text-align: left;">{{error}}</h3>
                        </template>
                        <template v-else>
                            <div style="height: 100%; display: flex; flex-direction: row; justify-content: center; align-items: center;">
                                <div class="loader"></div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!token" class="container" style="padding: 2rem;">
        <h2 style="font-size: 40px; color: white; margin: 0px; margin: 2vh; text-shadow: #000000 1px 0 10px;">Set the Metadata of your Mansions</h2>
        <h3 style="font-size: 28px; color: white; margin: 0px; margin: 2vh; text-shadow: #000000 1px 0 10px;">To set the Metadata of your Mansions, please sign in using your Metamask extension. If you can't see the pop-up, refresh the page.</h3>
    </div>
    <div class="container" style="padding: 2rem;" v-if="mansionsWithoutMetadata.length > 0">
        <h2 style="font-size: 40px; color: white; margin: 0px; margin: 2vh; text-shadow: #000000 1px 0 10px;">Set Mansions for your minted NFTs</h2>
        <div class="blurred-card" v-for="mansionNft in mansionsWithoutMetadata">
            <div style="flex: 1;">
                <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; margin-bottom: 0.5rem; margin-top: 0.5rem;">
                    <div style="display: flex; flex: 1; flex-direction: row; align-items: center; margin-right: 2rem;">
                        <img style="border-radius: 50%; width: 100px; height: 100px; margin-right: 1rem;" src="../../static/img/mansion-square.png" alt="oni-mansion">
                        <h3 class="mansion-title">Oni Mansion #{{mansionNft}}</h3>
                    </div>
                    <div style="flex: 0.5; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <Multiselect v-model="values[mansionNft]" valueProp="label" :options="nftsWithoutMansions" label="label"/>
                        <div class="modal-content connect-button" @click="linkMetadata(mansionNft)">
                            <div class="contents inline-menu mt-15">
                                <div class="p-discord">
                                    <div class="p-btn mb-10">
                                        <a class="mint-link" style="line-height: 32px; cursor: pointer; font-size: 18px;">Set Mansion</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="padding: 2rem;" v-if="mansionsWithMetadata.length > 0">
        <h2 style="font-size: 40px; color: white; margin: 0px; margin: 2vh; text-shadow: #000000 1px 0 10px;">Your Mansions</h2>
        <div class="blurred-card" v-for="mansionNft in mansionsWithMetadata">
            <div style="flex: 1;">
                <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 0.5rem; margin-top: 0.5rem;">
                    <div style="display: flex; flex: 1; flex-direction: row; align-items: center; margin-right: 2rem;">
                        <img style="border-radius: 50%; width: 100px; height: 100px; margin-right: 1rem;" :src="getImageUrl(mansionNft.linkedNft.collection, mansionNft.linkedNft.id)" alt="oni-mansion">
                        <div>
                            <h3 class="mansion-title">Oni Mansion #{{mansionNft.mansion_id}}</h3>
                            <h3 class="mansion-subtitle">{{getCollectionName(mansionNft.linkedNft.collection)}} #{{mansionNft.linkedNft.id}}</h3>
                        </div>
                    </div>
                    <div class="mansion-link">
                        <a :href="'#/oni-mansion-game/' + getContractAddress(mansionNft.linkedNft.collection) + '/' + mansionNft.linkedNft.id">Go to Oni Mansion</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="./MansionMintV2.js"></script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #a2a2a2;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.mint-page-container {
    min-height: -webkit-fill-available;
    background: url(../../static/img/forest_header_1.jpg) 50% bottom / cover no-repeat;
    padding-top: 80px;
    padding-bottom: 3rem;
}

.mint-mansions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mint-mansions-image {
    flex: 1;
    margin-right: 2rem;
}

@media only screen and (max-width: 719px) {
    .mint-mansions-image {
        display: none;
    }
}

.mint-mansions-content {
    flex: 1;
    margin-left: 2rem;
    color: white;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.mint-mansions-content .blurred-card {
    background-color: rgba(0, 0, 0, 0.5);
}

.blurred-card {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2rem;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.15);
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
  font-size: 16px;
  visibility: hidden;
  min-width: 300px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-align: center;
  padding: 1rem;
  border-radius: 6px;
  position: absolute;
  z-index: 800;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip-icon {
    text-shadow: none;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.4);
    color: black;
    font-size: 20px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    padding: 0px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mansion-title {
    font-size: 2rem;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
}

.mansion-subtitle {
    font-size: 1.5rem;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
}

.mansion-link {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 1.5rem;
}

@media only screen and (max-width: 719px) {
    .mansion-title {
        font-size: 1.5rem;
    }

    .mansion-subtitle {
        font-size: 1rem;
    }

    .mansion-link {
        font-size: 1rem;
    }
}
</style>