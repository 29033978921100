import axios from 'axios';
import * as ethers from 'ethers';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import Banner from '../../components/banner/Banner.vue';
import Navbar from '../../components/navbar/Navbar.vue';
import Multiselect from '@vueform/multiselect';
import { abi as OniMansionContractAbi } from './OniMansionTwo.json';

const BASE_URL = 'https://onisquad.gg';
// const BASE_URL = 'http://localhost:4000';
const ONI_MANSION_CONTRACT_ADDRESS = '0xF0d84dc2922e43cdc8f10a427D798BFfd4462447';
const api = axios.create({ baseURL: BASE_URL });

export default {
    name: 'MansionMintV2',
    components: {
        Banner,
        Multiselect,
        Navbar,
    },
    mounted: async function() {
        const resetState = async clearSession => {
            if (clearSession) {
                localStorage.removeItem('mintSessionToken');
            }

            this.ready = false;
            await this.initContracts();
            await this.refreshState();
            this.ready = true;
        }

        // need to reset the page state if the connected account changes
        ethereum.on('accountsChanged', () => resetState(true));

        await resetState(false);
    },
    data: function() {
        return {
            token: null,
            value: null,
            values: [],
            ready: false,
            oniMansionContract: null,
            mintingData: null,
            mansionsMetadata: [],
            ownedMansionNfts: [],
            mintAmount: 0,
            error: null,
        };
    },
    computed: {
        eligibleOwnedNfts: function() {
            return this.mintingData.nfts;
        },
        maxMintNumber: function() {
            return this.mintingData.nfts.length - this.ownedMansionNfts.length;
        },
        mansionsWithoutMetadata: function() {
            return this.ownedMansionNfts.filter(x => !this.mansionsMetadata.some(m => m.mansion_id === x));
        },
        mansionsWithMetadata: function() {
            const value = this.mansionsMetadata.map(m => ({
                ...m,
                linkedNft: this.mintingData.nfts.find(nft => nft.id === +m.token_id && m.contract.toLowerCase() === CONTRACT_ADDRESS[nft.collection].toLowerCase()),
            }));
            return value;
        },
        nftsWithoutMansions: function() {
            return this.mintingData.nfts
                .filter(nft => !this.mansionsMetadata.some(y => CONTRACT_ADDRESS[nft.collection].toLowerCase() === y.contract.toLowerCase() && nft.id === y.token_id))
                .map(nft => ({
                    ...nft,
                    label: `${this.getCollectionName(nft.collection)} #${nft.id}`,
                }));
        },
    },
    methods: {
        initContracts: async function() {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: Web3.utils.toHex('1') }]
            });

            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            const signer = provider.getSigner();

            this.wallet = await signer.getAddress();
            this.oniMansionContract = new ethers.Contract(ONI_MANSION_CONTRACT_ADDRESS, OniMansionContractAbi, signer);
        },
        refreshState: async function() {
            this.error = null;
            this.token = localStorage.getItem('mintSessionToken') || await authenticateJwt(this.wallet);
            this.mintingData = await loadMintingData(this.token);
            this.ownedMansionNfts = await loadOwnedMansions(this.wallet, this.oniMansionContract);
            this.mansionsMetadata = await loadMetadataForAllMansions(this.token);
        },
        getContractAddress: function(collection) {
            return CONTRACT_ADDRESS[collection];
        },
        getCollectionName: function(collection) {
            return COLLECTION_NAME[collection];
        },
        getImageUrl: function(collection, id) {
            switch(collection) {
                case 'onis': return `https://onisquadgg.mypinata.cloud/ipfs/QmSPM7RkHDDU6rZDGB3Z1Ww4u1xYq9CGRvhEtjBSM2V8yv/${id}.png`;
                case 'baby_spirits': return 'https://onisquadgg.mypinata.cloud/ipfs/QmTUo3HSxPVEnYJeKUW1eTsWoiveX54mQWzuhEpri2vhL2';
                case 'lazy_lions': return `https://onisquadgg.mypinata.cloud/ipfs/QmPnq86osUU3FhCkqwmMh7c55EyneqpXuuB99Q7B38poGz/${id}.jpeg`;
                case 'jungle_freaks': return `https://onisquadgg.mypinata.cloud/ipfs/QmZC3SkPDEmzC2xKwWb6gxbwv7Zoe7UuCuHbveJi4kjrzZ/${id}.jpeg`;
                case 'curious_addys': return `https://onisquadgg.mypinata.cloud/ipfs/QmSnmFPrvwAprSi1xfijmCZcbuqrdSe1UKa4s8j46YiGAM/${id}.png`;
            }

        },
        mint: async function() {
            if (this.mintAmount > this.maxMintNumber) {
                if (this.maxMintNumber) {
                    this.error = `You can't mint ${this.mintAmount} mansions; you can mint up to ${this.maxMintNumber} mansions.`
                } else {
                    this.error = `You can't mint any mansions.`
                }
                return;
            }
            this.ready = false;
            try {
                const tx = await this.oniMansionContract.mint(
                    this.mintingData.hash,
                    this.mintingData.signature,
                    this.mintAmount,
                    this.mintingData.nfts.length,
                );
                await tx.wait();
                await this.refreshState();
            } catch (err) {
                console.error(err);
                this.error = `An error occurred.`
            }
            this.ready = true;
        },
        linkMetadata: async function(mansionId) {
            const nft = this.nftsWithoutMansions.find(nft => nft.label === this.values[mansionId]);

            if (!nft) {
                return;
            }

            await setMetadataForMansion({ collection: nft.collection, mansionId, nftId: nft.id, token: this.token });

            this.ready = false;
            await this.refreshState();
            this.ready = true;
        },
    },
}

const COLLECTION_NAME = {
    'onis': 'Oni Squad',
    'baby_spirits': 'Baby Spirits',
    'lazy_lions': 'Lazy Lions',
    'jungle_freaks': 'Jungle Freaks',
    'curious_addys': 'Curious Addys',
};

const CONTRACT_ADDRESS = {
    'onis': '0x0ee7Bf57733e3DA8C015a0E7E395d641FAC512d6',
    'baby_spirits': '0xd5A22cB1EFA368c753b5b7a3879dAEB026e7B1f8',
    'lazy_lions': '0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0',
    'jungle_freaks': '0x7e6bc952d4b4bd814853301bee48e99891424de0',
    'curious_addys': '0x82712d0052c6d8185383b5554a071c440b902992',
};

async function loadMintingData(token) {
    const response = await api.get('api/mansion-mint/minting-data', {
        headers: { 'Authorization': 'Bearer ' + token }
    });
    return response.data;
}

async function loadOwnedMansions(wallet, contract) {
    const balance = await contract.balanceOf(wallet);
    const ids = [];
    for (let i = 0; i < balance; i++) {
        ids.push(await contract.tokenOfOwnerByIndex(wallet, i));
    }
    return ids.map(id => id.toNumber());
}

async function loadMetadataForAllMansions(token) {
    const response = await api.get('api/mansion-mint/mansions', {
        headers: { 'Authorization': 'Bearer ' + token }
    });
    return response.data.mansions;
}

async function authenticateJwt(wallet) {
    const result = await api.get(`api/metamask/nonce/${wallet}`);
    const signature = await (new Web3(ethereum)).eth.personal.sign(`OniSquadSignature-${result.data.nonce}`, wallet);
    const { data } = await api.post(`api/metamask/authenticate`, { publicAddress: wallet.toLowerCase(), signature });
    localStorage.setItem('mintSessionToken', data.token);
    return data.token;
}

async function setMetadataForMansion({ mansionId, collection, nftId, token }) {
    await api.post('api/mansion-mint/set-metadata', { mansionId, collection, nftId }, { headers: { 'Authorization': 'Bearer ' + token } });
}
